'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.devices.factory:DeviceInterfaceTypeManager

 # @description

###
angular
  .module 'mundoAdmin.devices'
  .factory 'DeviceInterfaceTypeManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      DeviceInterfaceTypeManagerBase = new MundoDefaultManager()
      DeviceInterfaceTypeManagerBase.setUrl('devices/interfaces/types')

      DeviceInterfaceTypeManagerBase.getList = ->
        types = []
        @transport.all(@conf.url).customGET("").then((data) ->
          for i, value of data.results
            types.push({type:value})
          types.sort (a,b) ->
            a.type.toLowerCase().localeCompare(b.type.toLowerCase())
          types
        )
        types

      DeviceInterfaceTypeManagerBase.forms = []
      DeviceInterfaceTypeManagerBase.getFormByType = (type, data = {}) ->
        switch type
          when 'Gsm'
            form =
              [
                key: 'apn'
                name: 'apn'
                type: 'input'
                defaultValue :
                  if data.apn then data.apn else ''
                templateOptions:
                  label: 'apn'
                  placeholder: 'apn'
              ,
                key: 'apnPassword'
                name: 'apnPassword'
                type: 'input'
                defaultValue :
                  if data.apnPassword then data.apnPassword else ''
                templateOptions:
                  label: 'apnPassword'
                  placeholder: 'apnPassword'
              ,
                key: 'apnUsername'
                name: 'apnUsername'
                type: 'input'
                defaultValue :
                  if data.apnUsername then data.apnUsername else ''
                templateOptions:
                  label: 'apnUsername'
                  placeholder: 'apnUsername'
              ,
                key: 'iccid'
                name: 'iccid'
                type: 'input'
                defaultValue :
                  if data.iccid then data.iccid else ''
                templateOptions:
                  label: 'iccid'
                  placeholder: 'iccid'
              ,
                key: 'imei'
                name: 'imei'
                type: 'input'
                defaultValue :
                  if data.imei then data.imei else ''
                templateOptions:
                    required: true
                    label: 'imei'
                    placeholder: 'imei'
              ,
                key: 'imsi'
                name: 'imsi'
                type: 'input'
                defaultValue :
                  if data.imsi then data.imsi else ''
                templateOptions:
                  label: 'imsi'
                  placeholder: 'imsi'
              ,
                key: 'phoneNumber'
                name: 'phoneNumber'
                type: 'input'
                defaultValue :
                  if data.phoneNumber then data.phoneNumber else ''
                templateOptions:
                  label: 'phoneNumber'
                  placeholder: 'phoneNumber'
              ,
                key: 'pin'
                name: 'pin'
                type: 'input'
                defaultValue :
                  if data.pin then data.pin else ''
                templateOptions:
                  label: 'pin'
                  placeholder: 'pin'
              ,
                key: 'puk'
                name: 'puk'
                type: 'input'
                defaultValue :
                  if data.puk then data.puk else ''
                templateOptions:
                  label: 'puk'
                  placeholder: 'puk'
              ]
          when 'Android'
            form =
              [
                key: 'androidId'
                name: 'androidId'
                type: 'input'
                defaultValue :
                  if data.androidId then data.androidId else ''
                templateOptions:
                  label: 'androidId'
                  placeholder: 'androidId'
              ]
          else form = []

        form

      DeviceInterfaceTypeManagerBase.new = (deviceId, data) ->
        @transport.one('devices', deviceId).all("interfaces/#{data.type}").post(_.pick(data, @getObject(data.type)))

      DeviceInterfaceTypeManagerBase.removeInterface = (deviceId, interfaceId) ->
        @transport.one('devices', deviceId).one("interfaces", interfaceId).remove()

      DeviceInterfaceTypeManagerBase.getInterfaceTypeForm = ->
        [
          key: 'type'
          name: 'type'
          type: 'select'
          templateOptions:
            label: 'type'
            placeholder: 'type'
            required: true
            labelProp: "type"
            valueProp: "type"
            options: @getList()
        ]

  #   Gsm type
      DeviceInterfaceTypeManagerBase.setObject(
        'Gsm',
        ['apn', 'apnPassword', 'apnUsername', 'iccid', 'imei', 'imsi', 'phoneNumber', 'pin', 'puk']
      )

      DeviceInterfaceTypeManagerBase.setObject(
        'Android',
        ['androidId']
      )

      DeviceInterfaceTypeManagerBase
  ]
